import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import StyledHero from "../components/StyledHero"
import styles from "../css/template.module.css"
import Img from "gatsby-image"
import { FaMoneyBillWave, FaClock, FaTypo3 } from "react-icons/fa"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import SEO from "../components/SEO"

const Template = ({ data }) => {
    const { name, description: { description }, technology: { technology }, images } = data.place;
    const [mainImage, ...placeImages] = images

    return (
        <Layout>
            <SEO title={name} />
            <StyledHero img={mainImage.fluid} />
            <section className={styles.template}>
                <div className={styles.center}>
                    <div className={styles.images}>
                        {placeImages && placeImages.map((item,index) =>{
                            return <Img key={index} fluid={item.fluid} alt="single" className={styles.image}/>
                        })}
                    </div>
                    <h2>{name}</h2>
                    <br/>
                    <h3>Description</h3>
                    <p className={styles.desc}><pre>{description}</pre></p>
                    <br/>
                    <h3>Technology</h3>
                    <p className={styles.desc}><pre>{technology}</pre></p>
                    <AniLink fade to="/projects" className="btn-primary">back to sample projects</AniLink>
                </div>
            </section>
        </Layout>
    )
}

export const query = graphql`
query($slug: String!){
    place: contentfulGreatProjects(slug:{eq: $slug}){
        name
        slug
        featured
        description{
            description
        }
        technology {
          technology
        }
        images{
            fluid{
                ...GatsbyContentfulFluid
            }
        }
    }
}
`
export default Template
